import React, { Component } from "react";
import {
    MDBAccordion,
    MDBAccordionItem,
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBPaginationItem,
    MDBPaginationLink
} from "mdb-react-ui-kit";
import { MDBCard, MDBCardBody, MDBCol, MDBPagination, MDBRow } from "mdbreact";
import { toast, ToastContainer } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import NoUsersFound from "./NoUsersFound";
import RightSideNavbar from "./rightSideNavbar";
import { withTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import Select from "react-select";
import { useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import { CiFilter } from "react-icons/ci";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { PiCirclesFourLight, PiChatTeardropTextLight } from "react-icons/pi";
import { RiHomeLine } from "react-icons/ri";
import { FaMessage, FaRegCircleUser } from "react-icons/fa6";
import { FaEdit, FaFilter, FaSave, FaSignOutAlt } from "react-icons/fa";


class Candidates_list_page extends Component {





    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            profile: null,
            users: null,
            current: null,
            num_pages: null,
            filter_empty: false,
            page: 1,
            isIntro: localStorage.getItem('intro'),
            ageRange: [20, 50],
            min_age: null,
            max_age: null,
            religious: null,
            religious_type: null,
            degree: null,
            nationality: null,
            is_filter: false,
            lang: this.props.params.lang,
            verified: null,
            isFaithEnable: true,
        }
        this.handleAgeRangeChange = this.handleAgeRangeChange.bind(this); // Binding here

        this.religious_lst = [
            { value: 1, label: 'اسلام' },
            { value: 2, label: 'مسیحی' }
        ]

        this.religious_lst_en = [
            { value: 1, label: 'Islam' },
            { value: 2, label: 'Christian' }
        ]

        this.religious_type_lst = [
            { value: 1, label: 'شیعه' },
            { value: 2, label: 'سنی' }
        ]

        this.religious_type_lst_en = [
            { value: 1, label: 'Shia' },
            { value: 2, label: 'Sunni' }
        ]
        this.degree_lst = [
            { value: 0, label: 'بدون مدرک' },
            { value: 1, label: 'سیکل' },
            { value: 2, label: 'دیپلم' },
            { value: 3, label: 'کاردانی' },
            { value: 4, label: 'لیسانس' },
            { value: 5, label: 'فوق لیسانس' },
            { value: 6, label: 'دکترا' },
            { value: 7, label: 'فوق دکترا' },
        ]

        this.degree_lst_en = [
            { value: 0, label: 'Other' },
            { value: 1, label: 'High School' },
            { value: 2, label: 'diploma' },
            // { value: 3, label: 'کاردانی' },
            { value: 4, label: 'Bachelor\'s degree' },
            { value: 5, label: 'Master\'s degree' },
            { value: 6, label: 'Ph.D.' },
            { value: 7, label: 'PostDoc' },
        ]

        this.nation_lst = [
            { value: 0, label: 'ایران' },
            { value: 4, label: 'انگلستان' },
            { value: 1, label: 'افغانستان' },
            { value: 2, label: 'آذربایجان' },
            { value: 3, label: 'پاکستان' },
        ]

        this.nation_lst_en = [
            { value: 0, label: 'Iran' },
            { value: 1, label: 'Afghanistan' },
            { value: 2, label: 'Azerbaijan' },
            { value: 3, label: 'Pakistan' },
            { value: 4, label: 'United Kingdom' },
        ]

        this.verified_fa = [
            { value: 0, label: 'خیر' },
            { value: 1, label: 'بله' },

        ]

        this.verified_en = [
            { value: 0, label: 'No Refrence' },
            { value: 1, label: 'Yes' },

        ]



    }
    handleAgeRangeChange = (range) => {
        this.setState({
          ageRange: range,         // Update the age range array
          min_age: range[0],       // Set the first value as min_age
          max_age: range[1],       // Set the second value as max_age
        });
      };
    chooseLang() {

        if (this.state.lang === 'fa') {
            localStorage.setItem("lang", this.state.lang);
        } else {
            if (this.state.lang === 'en') {
                localStorage.setItem("lang", this.state.lang);
            } else {
                if (localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'en');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () => {
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const { setLanguage } = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        this.getUserProfile()

    }


    getUserProfile() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch('/api/v1/profile/', requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));


                    if (response.data.religious === 1) {
                        this.getUsers(response.data, false)

                    } else {
                        this.getUsers(response.data, true)

                    }
                }

            })
            .catch((error) => {


            });
    }

    getUsers(profile, isFaithEnable) {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page', 1);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch('/api/v1/profiles?page=' + page, requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        isFaithEnable: false,
                        profile: response.data
                    })
                    this.setState({
                        isFaithEnable: isFaithEnable,
                        profile: profile,
                        users: response.users,
                        current: response.current,
                        num_pages: response.num_pages
                    });
                }

            })
            .catch((error) => {

                this.setState({
                    isFaithEnable: isFaithEnable,
                    profile: profile
                });
            });
    }

    mobileView() {

        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
            window.location.reload()
        };

        if (this.props.i18n.language === 'fa') {
            return (
                <body className="text-right " dir="rtl" style={{background:"#F5F5F5"}}>

                    <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3'} style={{ direction: 'ltr' }}>

                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='30'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>

                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={this.state.open}>

                            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                 <MDBNavbarItem>
                   <MDBNavbarLink

                     href="/my-profile"
                     style={{ fontWeight: "bold" }}
                   >
                     {this.props.i18n.t("my_profile")} <FaRegCircleUser />
                   </MDBNavbarLink>
                 </MDBNavbarItem>
 
                 <MDBNavbarItem>
                   <MDBNavbarLink
                     href="/learning"
                     style={{ fontWeight: "bold" }}
                   >
                     {this.props.i18n.t('plans')}
                     <RiHomeLine/>
                   </MDBNavbarLink>
                 </MDBNavbarItem>
 
 
                 {/* <MDBNavbarItem>
                                 <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')} </MDBNavbarLink>
                             </MDBNavbarItem> */}
 
                 <MDBNavbarItem>
                   <MDBNavbarLink                       active
                     aria-current="page" href="/users" style={{ fontWeight: "bold" }}>
                     {this.props.i18n.t('encounters')}
                     <PiCirclesFourLight />
                   </MDBNavbarLink>
                 </MDBNavbarItem>
 
                 <MDBNavbarItem>
                   <MDBNavbarLink
                     href="/chatroom"
                     style={{ fontWeight: "bold" }}
                   >
                     {this.props.i18n.t("chatroom")}
                     <PiChatTeardropTextLight />
                   </MDBNavbarLink>
                 </MDBNavbarItem>
 
                 <MDBNavbarItem>
                   <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                     {this.props.i18n.t("home_page_short")}
                   </MDBNavbarLink>
                 </MDBNavbarItem>
                 <MDBNavbarItem>
                   <MDBNavbarLink href="/logout" style={{ fontWeight: "bold" }}>
                     {this.props.i18n.t("sign_out")}
                     <FaSignOutAlt />
                   </MDBNavbarLink>
                 </MDBNavbarItem>
                            </MDBNavbarNav>

                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('en')} />
                                <MDBIcon flag='ir' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('fa')} />
                            </div>

                        </MDBCollapse>

                    </MDBNavbar>
{/* 
                    <MDBRow className={'m-0'}
                        style={{
                            minHeight: '160px'
                        }}>
                        <div className={'mt-5 text-left'}>
                            <button className="btn" style={{ background: '#FF686B', color: '#fff' }} onClick={(v) => this.chatBTN(v)}>
                                <label><FaMessage />{this.props.i18n.t('conversion_btn_title')}</label>
                            </button>
                        </div>
                    </MDBRow> */}
                    <MDBRow className={'m-0'}>
                        <div>
                        <style>{`.accordion-button::after { margin-right: 70% !important;     } `}   </style>

                            <MDBAccordion onChange={(itemId) => this.setIsCollapse(itemId)}>
                                <MDBAccordionItem collapseId={'filter-according'}
                                                headerTitle={
                                                <div className="accordion-header-custom">
                                                    <span  style={{fontSize:"1.7em", fontWeight:"bold"}}className="icon-left"><CiFilter /> </span> 
                                                    <span className="text">{this.props.i18n.t('filter_btn')}</span>
                                                </div>
                                                } >
                                


                                    <MDBRow className={'m-2'}>
                                        <span>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {this.props.i18n.t('age')}
                                            </span>
                                            <span style={{ direction: 'rtl', marginTop: '10px', fontWeight: 'bold', float: "left", paddingBottom: "5px", color: "#828282" }}>
                                                {`${this.state.ageRange[0]} - ${this.state.ageRange[1]} ${this.props.i18n.t('yearsnew')}`}
                                            </span>
                                        </span>
                                        <Slider
                                            style={{ margin: '10px 0px' }}
                                            range
                                            min={18}
                                            max={100}
                                            value={this.state.ageRange} // Use ageRange from state
                                            onChange={this.handleAgeRangeChange} // Update ageRange, min_age, and max_age on change
                                            allowCross={false}
                                            railStyle={{ backgroundColor: '#FFF', border: '0.5px solid #000', height: 10 }}
                                            trackStyle={[{ backgroundColor: '#D9D9D9', height: 10, border: '0.5px solid #000' }]} // Customize track color
                                            handleStyle={[
                                                {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',   // White background
                                                    borderColor: '#000',          // Black border
                                                    height: 25,                   // Increased height
                                                    width: 25,                    // Increased width
                                                    borderRadius: '50%',          // Makes the handles round
                                                    zIndex: 2
                                                },
                                                {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',   // White background
                                                    borderColor: '#000',          // Black border
                                                    height: 25,                   // Increased height
                                                    width: 25,                    // Increased width
                                                    borderRadius: '50%',          // Makes the handles round
                                                    zIndex: 2
                                                }
                                            ]}

                                        />
                                    </MDBRow>
                                    <MDBRow className={'m-0'}>


                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious')}</label>
                                        <Select
                                            options={this.religious_lst}
                                            onChange={value => this.religiousValue(value)}
                                            placeholder={this.props.i18n.t('religious')}
                                        />
                                    </MDBRow>
                                    {/* <MDBRow className={'m-0'}>

                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious_type')}</label>
                                        <Select
                                            isDisabled={this.state.isFaithEnable}
                                            id={'religious_type-id'}
                                            options={this.religious_type_lst}
                                            onChange={value => this.religious_typeValue(value)}
                                            placeholder={this.props.i18n.t('religious_type')}
                                        />
                                    </MDBRow> */}
                                    <MDBRow className={'m-0'}>

                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('grade_short')}</label>
                                        <Select
                                            options={this.degree_lst}
                                            onChange={value => this.degreeValue(value)}
                                            placeholder={this.props.i18n.t('grade_short')}
                                        />
                                    </MDBRow>
                                    <MDBRow className={'m-0'}>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('verifiedByRef')}</label>
                                                    <Select
                                                        options={this.verified_fa}
                                                        onChange={value => this.verified_fn(value)}
                                                        placeholder={this.props.i18n.t('verifiedByRef')}
                                                    />
                                                </MDBRow>

                                    {/* <MDBRow className={'m-0'}>
                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('nationality_title')}</label>
                                        <Select
                                            options={this.nation_lst}
                                            onChange={value => this.nationalityValue(value)}
                                            placeholder={this.props.i18n.t('nationality_title')}
                                        />
                                    </MDBRow> */}
                                    {/* <MDBRow className={'m-0'}>
                                        <div>
                                            <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('min_age')}</label>
                                            <input type="number" id="form12" name='min_age' className="form-control"
                                                onChange={(v) => this.min_age(v)} placeholder="" />
                                        </div>
                                    </MDBRow>
                                    <MDBRow className={'m-0'}>
                                        <div>
                                            <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('max_age')}</label>
                                            <input type="number" id="form12" name='max_age' className="form-control"
                                                onChange={(v) => this.max_age(v)} placeholder="" />
                                        </div>

                                    </MDBRow> */}
                                    <MDBRow className={'m-0 mt-2'}>

                                        <div className={'text-left'}>
                                        <button className="btn btn-block" style={{ background: '#2c2c2c', color: '#fff' }} onClick={(v) => this.filterBTN(v)}>
                                        <label><FaFilter />{this.props.i18n.t('filter_btn')}</label>
                                            </button>
                                        </div>
                                    </MDBRow>
                                </MDBAccordionItem>
                            </MDBAccordion>
                        </div>
                    </MDBRow>


                    <MDBRow className={'p-4 m-0'}>
                        {
                            this.state.users.map((item, index) => (
                                this.getAnItem(item, index)
                            ))
                        }
                    </MDBRow>

                    {this.getPagination()}


                </body>
            )
        } else {
            return (
                <body className="text-right" dir="ltr"  style={{ backgroundColor: "#F5F5F5" }}>

                    <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3 text-left'} style={{ direction: 'ltr' }}>

                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='30'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>

                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={this.state.open}>

                            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
 <MDBNavbarItem>
                  <MDBNavbarLink

                    href="/my-profile"
                    style={{ fontWeight: "bold" }}
                  >
                    <FaRegCircleUser style={{ marginTop: "-5px", marginRight: "5px" }} />
                    {this.props.i18n.t("my_profile")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/learning"
                    style={{ fontWeight: "bold" }}
                  >
                    <RiHomeLine style={{ marginTop: "-5px", marginRight: "5px" }}/>
                    {this.props.i18n.t('plans')}
                  </MDBNavbarLink>
                </MDBNavbarItem>



                <MDBNavbarItem>
                  <MDBNavbarLink                     active
                    aria-current="page" href="/users" style={{ fontWeight: "bold" }}>
                    <PiCirclesFourLight style={{ marginTop: "-5px", marginRight: "5px" }} />

                    {this.props.i18n.t('encounters')}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/chatroom"
                    style={{ fontWeight: "bold" }}
                  >
                    <PiChatTeardropTextLight style={{ marginTop: "-5px", marginRight: "5px" }} />
                    {this.props.i18n.t("chatroom")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("home_page_short")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/logout" style={{ fontWeight: "bold" }}>
                  <FaSignOutAlt  style={{ marginTop: "-5px", marginRight: "5px" }} />

                    {this.props.i18n.t("sign_out")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                                <div className={'mt-3'}>
                                    <MDBIcon flag='uk' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('en')} />
                                    <MDBIcon flag='ir' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('fa')} />
                                </div>

                            </MDBNavbarNav>


                        </MDBCollapse>

                    </MDBNavbar>

                    {/* <MDBRow className={'m-0'}
                        style={{
                            minHeight: '160px'
                        }}>
                        <div className={'mt-5 text-left'}>
                            <button className="btn" style={{ background: '#FF686B', color: '#fff' }} onClick={(v) => this.chatBTN(v)}>
                                <label><FaMessage />{this.props.i18n.t('conversion_btn_title')}</label>
                            </button>
                        </div>
                    </MDBRow> */}

                    <MDBRow className={'m-0 text-left'}>
                        <div>
                            <MDBAccordion onChange={(itemId) => this.setIsCollapse(itemId)}>
                                <MDBAccordionItem collapseId={'filter-according'}                                             
                                                headerTitle={
                                                <div className="accordion-header-custom">
                                                  <span  style={{fontSize:"1.7em", fontWeight:"bold"}}className="icon-left"><CiFilter /> </span> 
                                                  <span className="text">{this.props.i18n.t('filter_btn')}</span>
                                                </div>
                                              } >

                                    <MDBRow className={'m-2'}>
                                        <span>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {this.props.i18n.t('age')}
                                            </span>
                                            <span style={{ direction: 'ltr', marginTop: '10px', fontWeight: 'bold', float: "right", paddingBottom: "5px", color: "#828282" }}>
                                                {`${this.state.ageRange[0]} - ${this.state.ageRange[1]} ${this.props.i18n.t('yearsnew')}`}
                                            </span>
                                        </span>
                                        <Slider
                                            style={{ margin: '10px 0px' }}
                                            range
                                            min={18}
                                            max={100}
                                            value={this.state.ageRange} // Use ageRange from state
                                            onChange={this.handleAgeRangeChange} // Update ageRange, min_age, and max_age on change
                                            allowCross={false}
                                            railStyle={{ backgroundColor: '#FFF', border: '0.5px solid #000', height: 10 }}
                                            trackStyle={[{ backgroundColor: '#D9D9D9', height: 10, border: '0.5px solid #000' }]} // Customize track color
                                            handleStyle={[
                                                {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',   // White background
                                                    borderColor: '#000',          // Black border
                                                    height: 25,                   // Increased height
                                                    width: 25,                    // Increased width
                                                    borderRadius: '50%',          // Makes the handles round
                                                    zIndex: 2
                                                },
                                                {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',   // White background
                                                    borderColor: '#000',          // Black border
                                                    height: 25,                   // Increased height
                                                    width: 25,                    // Increased width
                                                    borderRadius: '50%',          // Makes the handles round
                                                    zIndex: 2
                                                }
                                            ]}

                                        />
                                    </MDBRow>
                                    <MDBRow className={'m-0'}>


                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious')}</label>
                                        <Select
                                            options={this.religious_lst_en}
                                            onChange={value => this.religiousValue(value)}
                                            placeholder={this.props.i18n.t('religious')}
                                        />
                                    </MDBRow>
                                    {/* <MDBRow className={'m-0'}>

                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious_type')}</label>
                                        <Select
                                            options={this.religious_type_lst_en}
                                            isDisabled={this.state.isFaithEnable}
                                            id={'religious_type-id'}
                                            onChange={value => this.religious_typeValue(value)}
                                            placeholder={this.props.i18n.t('religious_type')}
                                        />
                                    </MDBRow> */}
                                    <MDBRow className={'m-0'}>

                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('grade_short')}</label>
                                        <Select
                                            options={this.degree_lst_en}
                                            onChange={value => this.degreeValue(value)}
                                            placeholder={this.props.i18n.t('grade_short')}
                                        />
                                    </MDBRow>
                                    <MDBRow className={'m-0'}>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('verifiedByRef')}</label>
                                                    <Select
                                                        options={this.verified_en}
                                                        onChange={value => this.verified_fn(value)}
                                                        placeholder={this.props.i18n.t('verifiedByRef')}
                                                    />
                                                </MDBRow>
                                    {/* <MDBRow className={'m-0'}>
                                        <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('nationality_title')}</label>
                                        <Select
                                            options={this.nation_lst_en}
                                            onChange={value => this.nationalityValue(value)}
                                            placeholder={this.props.i18n.t('nationality_title')}
                                        />
                                    </MDBRow> */}
                                    {/* <MDBRow className={'m-0'}>
                                        <div>
                                            <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('min_age')}</label>
                                            <input type="number" id="form12" name='min_age' className="form-control"
                                                onChange={(v) => this.min_age(v)} placeholder="" />
                                        </div>
                                    </MDBRow>
                                    <MDBRow className={'m-0'}>
                                        <div>
                                            <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('max_age')}</label>
                                            <input type="number" id="form12" name='max_age' className="form-control"
                                                onChange={(v) => this.max_age(v)} placeholder="" />
                                        </div>

                                    </MDBRow> */}
                                    <MDBRow className={'m-0 mt-2'}>

                                        <div className={'text-left'}>
                                        <button className="btn btn-block" style={{ background: '#2c2c2c', color: '#fff' }} onClick={(v) => this.filterBTN(v)}>
                                        <label><FaFilter />{this.props.i18n.t('filter_btn')}</label>
                                            </button>
                                        </div>
                                    </MDBRow>
                                </MDBAccordionItem>
                            </MDBAccordion>
                        </div>
                    </MDBRow>

                    <MDBRow className={'p-4 m-0'}>
                        {
                            this.state.users.map((item, index) => (
                                this.getAnItem(item, index)
                            ))
                        }
                    </MDBRow>

                    {this.getPagination()}


                </body>
            )
        }

    }

    setIsCollapse(id) {

    }
    desktopView() {
        if (this.props.i18n.language === 'fa') {
            return (
                <body className="text-right bg-body"
                    dir="rtl"
                    style={{ height: "100vh", background: "#F5F5F5 !important" }}>
                    <div className={'container-fluid'} style={{ backgroundColor: "#F5F5F5" }}>

                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'candidate-list'}
                        />
                        <div style={{ marginRight: '300px', padding: '0px 10px' }}>
                            <MDBRow className={'m-0'} style={{
                                minHeight: '25%'
                            }}
                            >

                                {/* <div className={'mt-5 text-left'}>
                                    <button className="btn" style={{ background: '#FF686B', color: '#fff' }} onClick={(v) => this.chatBTN(v)}>
                                        <label><FaMessage />{this.props.i18n.t('conversion_btn_title')}</label>
                                    </button>
                                </div> */}
                            </MDBRow>
                            <MDBRow className={'m-0 p-4'} >
                                <div >
                                    <style>{`.accordion-button::after { margin-right: 92% !important;     } `}   </style>
                                    <MDBAccordion onChange={(itemId) => this.setIsCollapse(itemId)} >
                                        <MDBAccordionItem collapseId={'filter-according'} 
                                          headerTitle={
                                            <div className="accordion-header-custom">
                                              <span  style={{fontSize:"1.7em", fontWeight:"bold"}}className="icon-left"><CiFilter /> </span> 
                                              <span className="text">{this.props.i18n.t('filter_btn')}</span>
                                            </div>
                                          } >
                                            <div style={{ width: "50%" }}>

                                                <MDBRow className={'m-2'} >
                                                    <span>
                                                        <span style={{ fontWeight: 'bold' }}>
                                                            {this.props.i18n.t('age')}
                                                        </span>
                                                        <span style={{ direction: 'rtl', marginTop: '10px', fontWeight: 'bold', float: "left", paddingBottom: "5px", color: "#828282" }}>
                                                            {`${this.state.ageRange[0]} - ${this.state.ageRange[1]} ${this.props.i18n.t('yearsnew')}`}
                                                        </span>
                                                    </span>
                                                    <Slider
                                                        style={{ margin: '10px 0px' }}
                                                        range
                                                        min={18}
                                                        max={100}
                                                        value={this.state.ageRange} // Use ageRange from state
                                                        onChange={this.handleAgeRangeChange} // Update ageRange, min_age, and max_age on change
                                                        allowCross={false}
                                                        railStyle={{ backgroundColor: '#FFF', border: '0.5px solid #000', height: 10 }}
                                                        trackStyle={[{ backgroundColor: '#D9D9D9', height: 10, border: '0.5px solid #000' }]} // Customize track color
                                                        handleStyle={[
                                                            {
                                                                backgroundColor: 'rgba(255, 255, 255, 1)',   // White background
                                                                borderColor: '#000',          // Black border
                                                                height: 25,                   // Increased height
                                                                width: 25,                    // Increased width
                                                                borderRadius: '50%',          // Makes the handles round
                                                                zIndex: 2
                                                            },
                                                            {
                                                                backgroundColor: 'rgba(255, 255, 255, 1)',   // White background
                                                                borderColor: '#000',          // Black border
                                                                height: 25,                   // Increased height
                                                                width: 25,                    // Increased width
                                                                borderRadius: '50%',          // Makes the handles round
                                                                zIndex: 2
                                                            }
                                                        ]}

                                                    />
                                                </MDBRow>
                                                <MDBRow className={'m-0'}>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious')}</label>
                                                    <Select
                                                        options={this.religious_lst}
                                                        onChange={value => this.religiousValue(value)}
                                                        placeholder={this.props.i18n.t('religious')}
                                                    />
                                                </MDBRow>
                                                {/* <MDBRow className={'m-0'}>
                                                <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious_type')}</label>
                                                <Select
                                                    isDisabled={this.state.isFaithEnable}
                                                    id={'religious_type-id'}
                                                    options={this.religious_type_lst}
                                                    onChange={value => this.religious_typeValue(value)}
                                                    placeholder={this.props.i18n.t('religious_type')}
                                                />
                                            </MDBRow> */}
                                                <MDBRow className={'m-0'}>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('grade_short')}</label>
                                                    <Select
                                                        options={this.degree_lst}
                                                        onChange={value => this.degreeValue(value)}
                                                        placeholder={this.props.i18n.t('grade_short')}
                                                    />
                                                </MDBRow>

                                                <MDBRow className={'m-0'}>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('verifiedByRef')}</label>
                                                    <Select
                                                        options={this.verified_fa}
                                                        onChange={value => this.verified_fn(value)}
                                                        placeholder={this.props.i18n.t('verifiedByRef')}
                                                    />
                                                </MDBRow>


                                                {/* <MDBRow className={'m-0'}>
                                                <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('nationality_title')}</label>
                                                <Select
                                                    options={this.nation_lst}
                                                    onChange={value => this.nationalityValue(value)}
                                                    placeholder={this.props.i18n.t('nationality_title')}
                                                />
                                            </MDBRow> */}
                                                {/* <MDBRow className={'m-0'}>
                                                <div>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('min_age')}</label>
                                                    <input type="number" id="form12" name='min_age' className="form-control"
                                                        onChange={(v) => this.min_age(v)} placeholder="" />
                                                </div>
                                            </MDBRow>
                                            <MDBRow className={'m-0'}>
                                                <div>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('max_age')}</label>
                                                    <input type="number" id="form12" name='max_age' className="form-control"
                                                        onChange={(v) => this.max_age(v)} placeholder="" />
                                                </div>
                                            </MDBRow> */}
                                                <MDBRow className={'m-0 mt-2'}>

                                                    <div className={'text-left'}>
                                                        <button className="btn btn-block" style={{ background: '#2c2c2c', color: '#fff' }} onClick={(v) => this.filterBTN(v)}>
                                                            <label><FaFilter />{this.props.i18n.t('filter_btn')}</label>
                                                        </button>
                                                    </div>
                                                </MDBRow>
                                            </div>
                                        </MDBAccordionItem>
                                    </MDBAccordion>
                                </div>
                            </MDBRow>

                            <MDBRow className={' m-0 p-4 '}>

                                {this.state.users.map((item, index) => (
                                    this.getAnItem(item, index)
                                ))}
                            </MDBRow>
                            <MDBRow className="justify-content-center align-items-center text-center" >
                                {this.getPagination()}
                            </MDBRow>
                        </div>
                    </div>
                </body>
            )
        } else {
            return (
                <body className="text-right bg-body ltr"
                 dir="ltr" style={{ height: "100vh" ,  background: "#F5F5F5 !important"}}>
                    <div className={'container-fluid'}
                        style={{ backgroundColor: "#F5F5F5" }}>

                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'candidate-list'}
                        />
                        <div style={{ marginLeft: '300px', padding: '0px 10px' }}>
                            <MDBRow className={'m-0'} style={{
                                minHeight: '25%'
                            }}
                            >

                                {/* <div className={'mt-5 text-left'}>
                                    <button className="btn" style={{ background: '#FF686B', color: '#fff' }} onClick={(v) => this.chatBTN(v)}>
                                        <label><FaMessage />{this.props.i18n.t('conversion_btn_title')}</label>
                                    </button>
                                </div> */}
                            </MDBRow>

                            <MDBRow className={'m-0 p-4 text-left'}>
                                <div>
                                    <MDBAccordion initialActive={0}>
                                        <MDBAccordionItem
                                            collapseId={'filter-according'}

                                            headerTitle={
                                                <div className="accordion-header-custom">
                                                  <span  style={{fontSize:"1.7em", fontWeight:"bold"}}className="icon-left"><CiFilter /> </span> 
                                                  <span className="text">{this.props.i18n.t('filter_btn')}</span>
                                                </div>
                                              } >
                                                <div style={{ width: "50%" }}>
                                            <MDBRow className={'m-2'}>
                                                <span>
                                                    <span style={{ fontWeight: 'bold' }}>
                                                        {this.props.i18n.t('age')}
                                                    </span>
                                                    <span style={{ marginTop: '10px', fontWeight: 'bold', float: "right", paddingBottom: "5px", color: "#828282" }}>
                                                        {`${this.state.ageRange[0]} - ${this.state.ageRange[1]} ${this.props.i18n.t('yearsnew')}`}
                                                    </span>
                                                </span>
                                                <Slider
                                                    style={{ margin: '10px 0px' }}
                                                    range
                                                    min={18}
                                                    max={100}
                                                    value={this.state.ageRange} // Use ageRange from state
                                                    onChange={this.handleAgeRangeChange} // Update ageRange, min_age, and max_age on change
                                                    allowCross={false}
                                                    railStyle={{ backgroundColor: '#FFF', border: '0.5px solid #000', height: 10 }}
                                                    trackStyle={[{ backgroundColor: '#D9D9D9', height: 10, border: '0.5px solid #000' }]}
                                                    handleStyle={[
                                                        {
                                                        backgroundColor: 'rgba(255, 255, 255, 1)', // White background
                                                        borderColor: '#000',                      // Black border
                                                        height: 25,                               // Increased height
                                                        width: 25,                                // Increased width
                                                        borderRadius: '50%',                      // Makes the handles round
                                                        zIndex: 2
                                                        },
                                                        {
                                                        backgroundColor: 'rgba(255, 255, 255, 1)', // White background
                                                        borderColor: '#000',                      // Black border
                                                        height: 25,                               // Increased height
                                                        width: 25,                                // Increased width
                                                        borderRadius: '50%',                      // Makes the handles round
                                                        zIndex: 2
                                                        }
                                                    ]}
                                                    />

                                            </MDBRow>
                                            <MDBRow className={'m-0'}>
                                                <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious')}</label>
                                                <Select
                                                    options={this.religious_lst_en}
                                                    onChange={value => this.religiousValue(value)}
                                                    placeholder={this.props.i18n.t('religious')}
                                                />
                                            </MDBRow>
                                            {/* <MDBRow className={'m-0'}>
                                                <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('religious_type')}</label>
                                                <Select
                                                    options={this.religious_type_lst_en}
                                                    isDisabled={this.state.isFaithEnable}
                                                    id={'religious_type-id'}
                                                    onChange={value => this.religious_typeValue(value)}
                                                    placeholder={this.props.i18n.t('religious_type')}
                                                />
                                            </MDBRow> */}
                                            <MDBRow className={'m-0'}>
                                                <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('grade_short')}</label>
                                                <Select
                                                    options={this.degree_lst_en}
                                                    onChange={value => this.degreeValue(value)}
                                                    placeholder={this.props.i18n.t('grade_short')}
                                                />
                                            </MDBRow>
                                            <MDBRow className={'m-0'}>
                                                    <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('verifiedByRef')}</label>
                                                    <Select
                                                        options={this.verified_en}
                                                        onChange={value => this.verified_fn(value)}
                                                        placeholder={this.props.i18n.t('verifiedByRef')}
                                                    />
                                                </MDBRow>
                                            {/* <MDBRow className={'m-0'}>
                                                <label style={{ fontWeight: 'bold' }}>{this.props.i18n.t('nationality_title')}</label>
                                                <Select
                                                    options={this.nation_lst_en}
                                                    onChange={value => this.nationalityValue(value)}
                                                    placeholder={this.props.i18n.t('nationality_title')}
                                                />
                                            </MDBRow> */}
                                            {/* <MDBRow className={'m-0'}>
                                            <div>
                                                <label style={{fontWeight: 'bold'}}>{this.props.i18n.t('min_age')}</label>
                                                <input type="number" id="form12" name='min_age' className="form-control"
                                                       onChange={(v) => this.min_age(v)} placeholder=""/>
                                            </div>
                                        </MDBRow>
                                        <MDBRow className={'m-0'}>
                                            <div>
                                                <label style={{fontWeight: 'bold'}}>{this.props.i18n.t('max_age')}</label>
                                                <input type="number" id="form12" name='max_age' className="form-control"
                                                       onChange={(v) => this.max_age(v)} placeholder=""/>
                                            </div>
                                        </MDBRow> */}

                                            <MDBRow className={'m-0 mt-2'}>

                                                <div className={'text-left'}>
                                                <button className="btn btn-block" style={{ background: '#2c2c2c', color: '#fff' }} onClick={(v) => this.filterBTN(v)}>
                                                <label><FaFilter />{this.props.i18n.t('filter_btn')}</label>
                                                    </button>
                                                </div>
                                            </MDBRow>
                                            </div>
                                        </MDBAccordionItem>
                                    </MDBAccordion>
                                </div>
                            </MDBRow>

                            <MDBRow className={' m-0 p-4 '}>

                                {this.state.users.map((item, index) => (
                                    this.getAnItem(item, index)
                                ))}
                            </MDBRow>
                            <MDBRow className="justify-content-center align-items-center text-center" >
                                {this.getPagination()}
                            </MDBRow>
                        </div>
                    </div >
                </body >
            )
        }

    }

    filter_request(url) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch(url, requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.users.length === 0) {
                        toast.info(this.props.i18n.t('filter_not_found_toast'), {
                            position: toast.POSITION.TOP_LEFT
                        });
                        this.setState({
                            users: response.users,
                            current: response.current,
                            num_pages: response.num_pages
                        });
                    } else {
                        toast.info(this.props.i18n.t('filter_is_applied_toast'), {
                            position: toast.POSITION.TOP_LEFT
                        });

                        this.setState({
                            users: response.users,
                            current: response.current,
                            num_pages: response.num_pages
                        });
                    }
                }

            })
            .catch((error) => {
            });
    }
    filterBTN(v) {
        var url = '/api/v1/profiles?'
        if (this.state.min_age !== null && this.state.min_age !== undefined) {
            url += 'age_min=' + this.state.ageRange[0] + '&'
        }
        if (this.state.max_age !== null && this.state.max_age !== undefined) {
            url += 'age_max=' + this.state.ageRange[1] + '&'
        }
        if (this.state.religious !== null && this.state.religious !== undefined) {
            url += 'religious=' + this.state.religious + '&'
        }
        // if (this.state.religious_type !== null && this.state.religious_type !== undefined) {
        //     url += 'religious_type=' + this.state.religious_type + '&'
        // }
        if (this.state.degree !== null && this.state.degree !== undefined) {
            url += 'degree=' + this.state.degree + '&'
        }
        // if (this.state.nationality !== null && this.state.nationality !== undefined) {
        //     url += 'nationality=' + this.state.nationality
        // }
        if (this.state.verified !== null && this.state.verified !== undefined) {
            url += 'verified=' + this.state.verified
        }
        this.filter_request(url)
    }

    // min_age(v) {
    //     this.state.min_age = v.target.value;
    //     console.log(this.state.min_age)

    // }
    // max_age(v) {
    //     this.state.max_age = v.target.value;
    //     console.log(this.state.max_age)
    // }

    religiousValue(value) {
        this.state.religious = value['value'];

        if (value['value'] === 1) {
            this.setState({
                isFaithEnable: false
            })
        } else {
            this.setState({
                isFaithEnable: true
            })
        }

    }
    religious_typeValue(value) {
        this.state.religious_type = value['value'];
    }

    degreeValue(value) {
        this.state.degree = value['value'];
 

    }
    nationalityValue(value) {
        this.state.nationality = value['value'];

    }

    verified_fn(value){
        this.state.verified = value['value'];
        console.log(this.state.verified)
    }

    getPagination() {
        const next_p = parseInt(this.state.current) + 1
        const previous_p = parseInt(this.state.current) - 1
        if (this.state.current == 1 && this.state.num_pages > this.state.current) {
            return (
                <div className={'text-center'} style={{ marginLeft: '25px' }}>
                    <MDBPagination className=' text-center justify-content-center'>

                        <MDBPaginationItem>
                            <MDBPaginationLink
                                className="m-3"
                                href={"/users/?page=" + next_p}>Next</MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem className='page-item active' aria-current='page'>
                            <MDBPaginationLink tag='span' className='page-link m-3'>
                                {this.state.page}<span className='visually-hidden'>(current)</span>
                            </MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem disabled>
                            <MDBPaginationLink
                                className="m-3"
                                href={"/users/?page=" + previous_p}>{this.props.i18n.t('welcome_back_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                    </MDBPagination>
                </div>
            );
        }

        if (this.state.current == 1 && this.state.num_pages === this.state.current) {
            return (
                <div className={'text-center'} style={{ marginLeft: '25px' }}>
                    <MDBPagination className='text-center justify-content-center'>
                        <MDBPaginationItem disabled>
                            <MDBPaginationLink
                                href={"/users/?page=" + next_p}>{this.props.i18n.t('welcome_next_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem className='page-item active' aria-current='page'>
                            <MDBPaginationLink tag='span' className='page-link'>
                                {this.state.page}<span className='visually-hidden'>(current)</span>
                            </MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem disabled>
                            <MDBPaginationLink
                                href={"/users/?page=" + previous_p}>{this.props.i18n.t('welcome_back_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                    </MDBPagination>
                </div>
            );
        }

        if (this.state.num_pages > this.state.current) {
            return (
                <div className={'text-center'} style={{ marginLeft: '25px' }}>
                    <MDBPagination className='mb-0 text-center justify-content-center'>
                        <MDBPaginationItem>
                            <MDBPaginationLink
                                className="m-3"

                                href={"/users/?page=" + next_p}>{this.props.i18n.t('welcome_next_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem className='page-item active' aria-current='page'>
                            <MDBPaginationLink tag='span' className='page-link m-3'>
                                {this.state.page}<span className='visually-hidden'>(current)</span>
                            </MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem>
                            <MDBPaginationLink
                                className="m-3"
                                href={"/users/?page=" + previous_p}>{this.props.i18n.t('welcome_back_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                    </MDBPagination>
                </div>
            );
        } else {
            return (
                <div className={'text-center'} style={{ marginLeft: '25px' }}>
                    <MDBPagination className=' text-center justify-content-center'>
                        <MDBPaginationItem disabled>
                            <MDBPaginationLink
                                className="m-3"
                                href={"/users/?page=" + next_p}>{this.props.i18n.t('welcome_next_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem className='page-item active' aria-current='page'>
                            <MDBPaginationLink tag='span' className='page-link m-3'>
                                {this.state.page}<span className='visually-hidden'>(current)</span>
                            </MDBPaginationLink>
                        </MDBPaginationItem>
                        <MDBPaginationItem>
                            <MDBPaginationLink
                                className="m-3"
                                href={"/users/?page=" + previous_p}>{this.props.i18n.t('welcome_back_btn')}</MDBPaginationLink>
                        </MDBPaginationItem>
                    </MDBPagination>
                </div>
            );
        }
    }

    getAnItem(item, index) {
        return (

            <MDBCol xl={3} xs={6} md={6} className='mb-4' onClick={() => this.clickCard(item.id)} style={{ cursor: 'pointer' }}>
                <MDBCard className={'shadow-4-strong'}>
                    <MDBCardBody style={{border: "1px solid black", borderRadius:"10px"}}>
                        <MDBRow className={'text-center card-center mb-2'} style={{display:'unset'}}>
                            <div
                                className="image-container"
                                style={{
                                    position: 'relative', // For badge placement
                                    display: 'flex', // Flexbox for alignment
                                    justifyContent: 'center', // Horizontal centering
                                    alignItems: 'center', // Vertical centering
                                    flexDirection: 'column', // Keep items in a column
                                    width: '100%', // Ensures it takes the card's width
                                    
                                }}
                            >
                                {/* Background image */}
                                <div
                                    className="bg-image-container"
                                    style={{
                                        backgroundImage: `url(${item.image})`,
                                        width: '180px', // Set width for the container (adjust as needed)
                                        height: '180px', // Set height for the container (adjust as needed)
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: '100%', // Optional: Rounded corners
                                    }}
                                ></div>

                                {/* Verification badge */}
                                {item.is_verified && (
                                    <img
                                        src="/verified.png" // Path to the image in the public folder
                                        alt="Verified"
                                        style={{
                                            position: 'absolute', // Badge is positioned relative to the container
                                            bottom: '-16px', // Adjust badge below the image
                                            right: 'calc(31%)', // Centers the badge horizontally
                                            transform: 'translateX(50%)', // Keeps it perfectly aligned
                                            width: '45px',
                                            height: '45px',
                                            borderRadius: '50%',
                                        }}
                                    />
                                )}
                            </div>
                        </MDBRow>

                        <MDBRow className={'text-center mt-2'}>
                            <div className=''>
                                <p className='fw-bold mb-1'>{item.name} / {item.age}</p>
                            </div>
                        </MDBRow>
                        <MDBRow className={'text-center mt-1'}>
                            <div className=''>
                            {item.city && item.country ? (
                                <p className='mb-1'>
                                {this.capitalizeFirstLetter(item.city)}, {this.capitalizeFirstLetter(item.country)}
                                </p>
                            ) : null}
                            </div>
                        </MDBRow>                 
                        {/* <MDBRow className="justify-content-center align-items-center text-center" >
                            <div style={{ width: 100, height: 100 }} className={'text-center'}>
                                <CircularProgressbar
                                    styles={buildStyles({
                                        backgroundColor: '#3ec749',
                                        pathColor: '#3ec749',
                                    })}
                                    value={item.similarity} text={`${item.similarity}%`} />
                            </div>
                        </MDBRow> */}

                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )
    }

    clickCard(id) {
        window.location.href = '/user/?id=' + id
    }

    capitalizeFirstLetter(string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }

    chatBTN(st) {
        window.location.href = '/chatroom'
    }

    render() {
        if (localStorage.getItem("token") === null) {
            return (
                <div style={{ fontWeight: 'bold', fontSize: '25px', direction: 'ltr' }}>
                    Forbidden!
                </div>
            );
        }
        if (this.state.profile !== null) {
            if (this.state.users !== null && this.state.users !== undefined) {
                if (this.state.users.length > 0) {
                    return (
                        <div>
                            <ToastContainer />
                            <BrowserView>
                                {this.desktopView()}
                            </BrowserView>
                            <MobileView>
                                {this.mobileView()}
                            </MobileView>
                        </div>
                    );
                } else {
                    return (
                        <body className="text-right bg-body" dir="rtl">
                            <div className={'container-fluid'}>

                                <MDBRow className="mt-5 pt-4"></MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <img width={200} className={'img-fluid'} src="images/success-reg.png" />
                                    </div>
                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                                    <div>
                                        <p>
                                            {this.props.i18n.t('user_not_found')}
                                        </p>
                                    </div>

                                </MDBRow>
                                <MDBRow
                                    className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <a href='/my-profile' className="btn w-20 mt-3"
                                            style={{ backgroundColor: '#C4C4C4', color: '#fff' }}>
                                            {this.props.i18n.t('back_btn')}
                                        </a>
                                    </div>
                                </MDBRow>
                                <MDBRow className="mt-5 pt-4"></MDBRow>
                            </div>
                        </body>
                    );
                }
                return (
                    <body className="text-right bg-body" dir="rtl">
                        <div className={'container-fluid'}>

                            <MDBRow className="mt-5 pt-4"></MDBRow>
                            <MDBRow
                                className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                <div>
                                    <img width={200} className={'img-fluid'} src="images/success-reg.png" />
                                </div>
                            </MDBRow>
                            <MDBRow
                                className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                                <div>
                                    <p>
                                        {this.props.i18n.t('user_not_found')}
                                    </p>
                                </div>

                            </MDBRow>
                            <MDBRow
                                className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                <div>
                                    <a href='/' className="btn w-20 mt-3"
                                        style={{ backgroundColor: '#C4C4C4', color: '#fff' }}>
                                        {this.props.i18n.t('back_btn')}
                                    </a>
                                </div>
                            </MDBRow>
                            <MDBRow className="mt-5 pt-4"></MDBRow>
                        </div>
                    </body>
                );
            } else {
                return (
                    <NoUsersFound
                        profile={this.state.profile}
                        isEn={this.props.i18n.language === 'en'}
                    />
                );
            }
        } else {
            return (
                <div style={{ fontWeight: 'bold', fontSize: '25px', direction: 'ltr' }}>

                </div>
            );

        }

    }

}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export default connect()(withParams(withTranslation()(Candidates_list_page)));
