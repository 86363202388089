import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import Home_page from "./components/home_page";
import Authentication from "./components/authentication";
import AboutUs from "./components/aboutus";
import QuestionsPage from "./components/questionsPage";
import EditProfile from "./components/EditProfile";
import UserProfile from "./components/UserProfile";
import Logout from "./components/Logout";
import LearningPage from "./components/LearningPage";
import ChatRoomPage from "./components/ChatRoomPage";
import SupportChatRoom from "./components/SupportChatRoom";
import VideoLearningPage from "./components/videoLearningPage";
import SurveyPage from "./components/survey_page";
import Candidates_list_page from "./components/Candidates_list_page";
import CandidateProfile from "./components/CandidateProfile";
import ContactUs from "./components/contact-us";
import RegisterForm from "./components/register_form";
import { LanguageProvider } from "./LanguageContext";
import { useTranslation } from 'react-i18next';
import EmailVerification from "./components/EmailVerification";
import UserValidation from "./components/UserValidation";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import ChatSupportIcon from "./components/ChatSupportIcon";  
import Plans from "./components/plans";

function App(){
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = t('title');  // Update document title
    }, [i18n.language]);  // Dependency on language

    return(
        <LanguageProvider>
            <Routes>
                <Route path='/:lang?' element={<Home_page />} />
                <Route path='/authentication/:lang?' element={<Authentication />} />
                <Route path='/register/:lang?' element={<RegisterForm />} />
                <Route path='/about-us/:lang?' element={<AboutUs />} />
                <Route path='/contact-us/:lang?' element={<ContactUs />} />
                <Route path='/my-profile/:lang?' element={<UserProfile />} />
                <Route path='/edit-profile/:lang?' element={<EditProfile />} />
                <Route path='/learning/:lang?' element={<LearningPage />} />
                <Route path='/logout/:lang?' element={<Logout />} />
                <Route path='/chatroom/:lang?' element={<ChatRoomPage />} />
                <Route path='/supportchatroom/:lang?' element={<SupportChatRoom />} />
                <Route path='/learning/videos/:lang?' element={<VideoLearningPage />} />
                <Route path='/learning/questions/:lang?' element={<SurveyPage />} />
                <Route path='/users/:lang?' element={<Candidates_list_page />} />
                <Route path='/user/:lang?' element={<CandidateProfile />} />
                <Route path='/validation/:lang?' element={<EmailVerification />} />
                <Route path='/validate/:lang?' element={<UserValidation />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/plans' element={<Plans />} />
            </Routes>
            {/* <ChatSupportIcon />  */}
        </LanguageProvider>
    );
}

export default App;
