import React, { Component } from "react";
import { MDBCol, MDBRow, MDBTable, MDBTableBody } from "mdbreact";
import { FaEdit, FaFilter, FaSave, FaSignOutAlt } from "react-icons/fa";
import { FaMessage, FaRegCircleUser } from "react-icons/fa6";
import { PiCirclesFourLight, PiChatTeardropTextLight } from "react-icons/pi";

import Select from "react-select";
import { BrowserView, MobileView } from "react-device-detect";
import {
  MDBCollapse,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import IntroPage from "./IntroPage";
import RightSideNavbar from "./rightSideNavbar";
import { LanguageContext } from "../LanguageContext";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router";
import { connect } from "react-redux";
import CheckboxOption from "./CheckboxOption";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { RiHomeLine } from "react-icons/ri";


class EditProfile extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      profile: null,
      isIntro: localStorage.getItem("intro"),
      SelectedFile: null,
      lang: this.props.params.lang,
      isFaithEnable: true,
    };

    this.gender_lst = [
      { value: 0, label: "زن" },
      { value: 1, label: "مرد" },
    ];

    this.gender_lst_en = [
      { value: 0, label: "Female" },
      { value: 1, label: "Male" },
    ];

    this.religious_lst = [
      { value: 1, label: "اسلام" },
      { value: 2, label: "مسیحی" },
    ];

    this.marital_status_lst = [
      { value: 1, label: "مجرد" },
      { value: 2, label: "بیوه" },
      { value: 3, label: "مطلقه" },
    ];

    this.language_lst = [
      { value: 1, label: "فارسی" },
      { value: 2, label: "انگلیسی" },
    ];

    this.religious_lst_en = [
      { value: 1, label: "Islam" },
      { value: 2, label: "Christian" },
    ];

    this.marital_status_lst_en = [
      { value: 1, label: "Single" },
      { value: 2, label: "Widow" },
      { value: 3, label: "Divorced" },
    ];

    this.language_lst_en = [
      { value: 1, label: "Persian/Farsi" },
      { value: 2, label: "English" },
    ];

    this.filter_lst_en = [
      { value: "height", label: "Height" },
      { value: "weight", label: "Weight" },
      { value: "Level_of_Education", label: "Level of Education" },
      { value: "Field_of_Study", label: "Field of Study" },
      { value: "Occupation", label: "Occupation" },
      { value: "City_you_live", label: "City you live" },
      { value: "Marital_Status", label: "Marital Status" },
      { value: "Number_of_Children", label: "Number of Children" },
      { value: "Languages", label: "Languages" },
      { value: "Religious", label: "Religious" },
      { value: "religious_type", label: "Faith" },
      { value: "nationality", label: "Nationality" },
      { value: "age", label: "Age" },
    ];

    this.filter_lst_fa = [
      { value: "height", label: "قد" },
      { value: "weight", label: "وزن" },
      { value: "Level_of_Education", label: "سطح تحصیلات" },
      { value: "Field_of_Study", label: "رشته تحصیلی" },
      { value: "Occupation", label: "شغل" },
      { value: "City_you_live", label: "شهر محل اقامت" },
      { value: "Marital_Status", label: "وضعیت تأهل" },
      { value: "Number_of_Children", label: "تعداد فرزندان" },
      { value: "Languages", label: "زبان‌ها" },
      { value: "Religious", label: "مذهبی" },
      { value: "religious_type", label: "دین" },
      { value: "nationality", label: "تابعیت" },
      { value: "age", label: "سن" },
    ];

    this.religious_type_lst = [
      { value: 1, label: "شیعه" },
      { value: 2, label: "سنی" },
    ];

    this.religious_type_lst_en = [
      { value: 1, label: "Shia" },
      { value: 2, label: "Sunni" },
    ];

    this.degree_lst = [
      { value: 0, label: "بدون مدرک" },
      { value: 1, label: "سیکل" },
      { value: 2, label: "دیپلم" },
      { value: 3, label: "کاردانی" },
      { value: 4, label: "لیسانس" },
      { value: 5, label: "فوق لیسانس" },
      { value: 6, label: "دکترا" },
      { value: 7, label: "فوق دکترا" },
    ];

    this.degree_lst_en = [
      { value: 0, label: "Other" },
      { value: 1, label: "High School" },
      { value: 2, label: "diploma" },
      { value: 3, label: "Associate degree" },
      { value: 4, label: "Bachelor's degree" },
      { value: 5, label: "Master's degree" },
      { value: 6, label: "Ph.D." },
      { value: 7, label: "PostDoc" },
    ];

    this.languages_lst_en = [
      { value: 0, label: "Persian/Farsi" },
      { value: 1, label: "English" },
      { value: 2, label: "Spanish" },
      { value: 3, label: "French" },
      { value: 4, label: "German" },
      { value: 5, label: "Chinese (Simplified)" },
      { value: 6, label: "Arabic" },
      { value: 7, label: "Russian" },
      { value: 8, label: "Japanese" },
      { value: 9, label: "Portuguese" },
      { value: 10, label: "Italian" },
      { value: 11, label: "Dutch" },
    ];

    this.languages_lst_fa = [
      { value: 0, label: "فارسی" },
      { value: 1, label: "انگلیسی" },
      { value: 2, label: "اسپانیایی" },
      { value: 3, label: "فرانسوی" },
      { value: 4, label: "آلمانی" },
      { value: 5, label: "چینی" },
      { value: 6, label: "عربی" },
      { value: 7, label: "روسی" },
      { value: 8, label: "ژاپنی" },
      { value: 9, label: "پرتغالی" },
      { value: 10, label: "ایتالیایی" },
      { value: 11, label: "هلندی" },
    ];
  }

  chooseLang() {
    if (this.state.lang === "fa") {
      localStorage.setItem("lang", this.state.lang);
    } else {
      if (this.state.lang === "en") {
        localStorage.setItem("lang", this.state.lang);
      } else {
        if (localStorage.getItem("lang") === null) {
          localStorage.setItem("lang", "en");
        }
      }
    }

    var l = localStorage.getItem("lang");
    this.languageChangeListener = () => {
      var lang = localStorage.getItem("lang");
    };

    this.props.i18n.on("languageChanged", this.languageChangeListener);

    if (l !== null) {
      const { setLanguage } = this.context;
      setLanguage(l);

      this.props.i18n.changeLanguage(l);
    }
  }

  componentDidMount() {
    this.chooseLang();

    this.getUserProfile();
  }

  getUserProfile() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    fetch("/api/v1/profile/", requestOptions)
      .then((response) => {
        if (!response.ok) throw response.status;
        else return response.json();
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            "profile",
            JSON.stringify({
              is_registration_complete: response.data.is_registration_complete,
              is_seen_videos: response.data.is_seen_videos,
              is_verified: response.data.is_verified,
            })
          );

          if (response.data.religious === 1) {
            this.setState({
              isFaithEnable: false,
              profile: response.data,
            });
          } else {
            this.setState({
              profile: response.data,
              isFaithEnable: true,
            });
          }
        }
      })
      .catch((error) => { });
  }

  mobileView() {
    const { setLanguage } = this.context;

    const changeLangBTN = (newLanguage) => {
      localStorage.setItem("lang", newLanguage);
      setLanguage(newLanguage);
      window.location.reload();
    };

    if (this.props.i18n.language == "fa") {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-right bg-body"
            dir="rtl"
            style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
          >
            <div className={"container-fluid"}>
              <MDBNavbar
                expand="lg"
                light
                bgColor="light"
                className={"p-3"}
                style={{ direction: "ltr" }}
              >
                <MDBNavbarBrand href="/">
                  <img
                    src="/logo192.png"
                    height="30"
                    alt="کانون هدایت"
                    loading="lazy"
                  />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  <MDBIcon icon="bars" fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={this.state.open}>
                  <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        active
                        aria-current="page"
                        href="/my-profile"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("my_profile")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    
                    <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/learning"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t('plans')}
                    <RiHomeLine />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/learning"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('plans')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/users"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('encounters')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                        {this.props.i18n.t("home_page_short")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/logout"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("sign_out")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <div className={"mt-3"}>
                      <MDBIcon
                        flag="uk"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("en")}
                      />
                      <MDBIcon
                        flag="ir"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("fa")}
                      />
                    </div>
                  </MDBNavbarNav>
                </MDBCollapse>
              </MDBNavbar>

              <IntroPage />
            </div>
          </body>
        );
      }
      return (
        <body
          className="text-right bg-body"
          dir="rtl"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <MDBNavbar
            expand="lg"
            light
            bgColor="light"
            className={"p-3 mb-3"}
            style={{ direction: "ltr" }}
          >
            <MDBNavbarBrand href="/">
              <img
                src="/logo192.png"
                height="30"
                alt="کانون هدایت"
                loading="lazy"
              />
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar show={this.state.open}>
              <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                <MDBNavbarItem>
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="/my-profile"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t("my_profile")} <FaRegCircleUser />
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/learning"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t('plans')}
                    <RiHomeLine />
                  </MDBNavbarLink>
                </MDBNavbarItem>
                {/* <MDBNavbarItem>
                                <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')} </MDBNavbarLink>
                            </MDBNavbarItem> */}

                <MDBNavbarItem>
                  <MDBNavbarLink href="/users" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t('encounters')}
                    <PiCirclesFourLight />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/chatroom"
                    style={{ fontWeight: "bold" }}
                  >
                    {this.props.i18n.t("chatroom")}
                    <PiChatTeardropTextLight />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("home_page_short")}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink href="/logout" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("sign_out")}
                    <FaSignOutAlt />
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <div className={"mt-3"}>
                  <MDBIcon
                    flag="uk"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("en")}
                  />
                  <MDBIcon
                    flag="ir"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("fa")}
                  />
                </div>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>

          {/*Profile image*/}
          <MDBRow className="mb-5">
            <MDBRow className="text-center">
              <div
                className="text-center mt-4"
                style={{ position: "relative", display: "inline-block", paddingRight:"0" }}
              >
                <GoArrowLeft
                  cursor={"pointer"}
                  onClick={(v) => this.goBack()}
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "30px",
                    fontSize: "30px",
                    color: "black",
                  }}
                />
                <img
                  height={180}
                  src={this.state.profile.picture}
                  className="shadow-5-strong"
                  style={{ borderRadius: "10%" }}
                  alt="Profile"
                />
                <label
                  htmlFor="inputField"
                  className="btn m-2"
                  style={{
                    background: "#C4C4C4",
                    color: "#fff",
                    position: "absolute",
                    bottom: "-20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <FaEdit /> {this.props.i18n.t("choose_avatar")}
                </label>
                <input
                  className="btn"
                  id="inputField"
                  style={{ display: "none" }}
                  type="file"
                  onChange={this.handleFileChange}
                />
              </div>
            </MDBRow>
          </MDBRow>

          {/* about me section */}
          <MDBRow className={"bg-white p-0 m-0"}>
            <div style={{ background: "#fff" }}>
              <label style={{ fontWeight: "bold" }}>
                {this.props.i18n.t("about_me")}
              </label>
              <textarea
                rows={8}
                name="about_me"
                className="form-control text-left ltr"
                onChange={(v) => this.about_me_Value(v)}
              >
                {this.state.profile.about_me}
              </textarea>
            </div>

            <div style={{ background: "#fff" }}>
              <label
                className={"mt-4"}
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >
                {this.props.i18n.t("My_info")}
              </label>
            </div>
          </MDBRow>
          <MDBRow className={"bg-white p-0 m-0"}>
            <MDBTable borderless className={"bg-white p-0 m-0"}>
              <MDBTableBody>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.i18n.t("username")}
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={this.state.profile.username}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("grade")}
                    </label>
                    <Select
                      options={this.degree_lst}
                      defaultValue={
                        this.degree_lst_en[this.state.profile.degree]
                      }
                      onChange={(value) => this.degreeValue(value)}
                      placeholder={this.props.i18n.t("grade")}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("major")}
                    </label>
                    <input
                      type="text"
                      name="major"
                      className="form-control"
                      onChange={(v) => this.majorValue(v)}
                      value={this.state.profile.major}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Occupation")}
                    </label>
                    <input
                      type="text"
                      name="Occupation"
                      className="form-control"
                      onChange={(v) => this.occupationInputValue(v)}
                      value={this.state.profile.occupation}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("City")}
                    </label>
                    <input
                      type="text"
                      name="weight"
                      className="form-control"
                      onChange={(v) => this.cityInputValue(v)}
                      value={this.state.profile.city_of_live}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Country")}
                    </label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      onChange={(v) => this.countryInputValue(v)}
                      value={this.state.profile.country_of_live}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Marital_Status")}
                    </label>
                    <Select
                      options={this.marital_status_lst}
                      defaultValue={
                        this.marital_status_lst[
                        this.state.profile.martial_status - 1
                        ]
                      }
                      onChange={(value) => this.marital_statusValue(value)}
                      placeholder={this.props.i18n.t("Marital_Status")}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Number_of_Children")}
                    </label>
                    <input
                      type="number"
                      name="weight"
                      className="form-control"
                      style={{ height: "38px" }}
                      onChange={(value) => this.number_of_childrenValue(value)}
                      value={this.state.profile.number_of_children}
                    />
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("language")}
                    </label>
                    <Select
                      components={{ Option: CheckboxOption }}
                      isMulti
                      options={this.languages_lst_en}
                      defaultValue={this.state.profile.languages}
                      onChange={value => this.languageValue(value)}
                      placeholder={this.props.i18n.t("language")}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Height")}
                    </label>
                    <input
                      type="number"
                      name="height"
                      className="form-control"
                      onChange={(v) => this.heightInputValue(v)}
                      value={this.state.profile.height}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("weight")}
                    </label>
                    <input
                      type="number"
                      name="weight"
                      className="form-control"
                      onChange={(v) => this.weightInputValue(v)}
                      value={this.state.profile.weight}
                    />
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>

            {/* privacy section */}

            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("privacy_txt")}
              </label>
            </div>
            <MDBRow
              className={"mb-5"}
              style={{
                display: "grid",
                gridTemplateColumns: "10fr 2fr",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <label
                  htmlFor="show_verified"
                  style={{
                    marginLeft: "8px",
                    fontSize: "12px",
                    marginRight: "10px",
                  }}
                >
                  {this.props.i18n.t("privacy_check")}
                </label>
              </div>

              <div>
                <label
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "45px",
                    height: "23px",
                    marginRight: "40px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="show_verified"
                    defaultChecked={this.state.profile.show_verified}
                    onChange={(e) => {
                      this.state.profile.show_verified = e.target.checked; // Direct state change
                    }}
                    style={{
                      opacity: 0,
                      width: "0",
                      height: "0",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "#ccc",
                      transition: "0.4s",
                      borderRadius: "34px",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        content: '""',
                        height: "20px", // Ball height
                        width: "20px", // Ball width
                        borderRadius: "50%",
                        border: "1px solid black",
                        left: "2px", // Adjust to center the ball properly
                        bottom: "2px", // Adjust the vertical position of the ball
                        backgroundColor: "white",
                        transition: "0.4s",
                      }}
                    ></span>
                  </span>
                </label>

                <style>
                  {`
        input:checked + span {
          background-color: rgb(121, 214, 124)!important;
        }

        input:checked + span span {
          transform: translateX(21px); 
        }
      `}
                </style>
              </div>
            </MDBRow>


            <MDBRow className={"mb-5"}>
              <MDBCol
                className={
                  "text-center align-items-end justify-content-end   d-flex"
                }
              >
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto  align-bottom"}
                  >
                    <button
                      className="btn btn-success"
                      onClick={(v) => this.updateProfileSubmit(v)}
                      style={{
                        background: "#355070",
                        color: "#fff",
                        width: "105px",
                        marginTop: "20px",
                      }}
                    >
                      <label>
                        {this.props.i18n.t("save_btn")}
                      </label>
                    </button>
                  </td>
                </tr>
              </MDBCol>
              <MDBCol
                className={
                  "text-center align-items-end justify-content-left d-flex"
                }
              >
                <button
                  className="btn"
                  style={{
                    background: "#FFF",
                    color: "#355070",
                    marginTop: "20px",
                    width: "105px",
                    border: "0.5px solid black",
                  }}
                  onClick={(v) => this.goBack()}
                >
                  <label>{this.props.i18n.t("cancel_btn_report")}</label>
                </button>
              </MDBCol>
            </MDBRow>
          </MDBRow>
        </body>
      );
    } else {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-left bg-body"
            dir="ltr"
            style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
          >
            <div className={"container-fluid"}>
              <MDBNavbar
                expand="lg"
                light
                bgColor="light"
                className={"p-3"}
                style={{ direction: "ltr" }}
              >
                <MDBNavbarBrand href="/">
                  <img
                    src="/logo192.png"
                    height="30"
                    alt="کانون هدایت"
                    loading="lazy"
                  />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  <MDBIcon icon="bars" fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={this.state.open}>
                  <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        active
                        aria-current="page"
                        href="/my-profile"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("my_profile")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/learning"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('plans')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/users"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t('encounters')}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                        {this.props.i18n.t("home_page_short")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                      <MDBNavbarLink
                        href="/logout"
                        style={{ fontWeight: "bold" }}
                      >
                        {this.props.i18n.t("sign_out")}
                      </MDBNavbarLink>
                    </MDBNavbarItem>

                    <div className={"mt-3"}>
                      <MDBIcon
                        flag="uk"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("en")}
                      />
                      <MDBIcon
                        flag="ir"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLangBTN("fa")}
                      />
                    </div>
                  </MDBNavbarNav>
                </MDBCollapse>
              </MDBNavbar>

              <IntroPage />
            </div>
          </body>
        );
      }
      return (
        <body
          className="text-left bg-body ltr"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <MDBNavbar
            expand="lg"
            light
            bgColor="light"
            className={"p-3 mb-3 text-left"}
            style={{ direction: "ltr" }}
          >
            <MDBNavbarBrand href="/">
              <img
                src="/logo192.png"
                height="30"
                alt="کانون هدایت"
                loading="lazy"
              />
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar show={this.state.open}>
              <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
                <MDBNavbarItem>
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="/my-profile"
                    style={{ fontWeight: "bold" }}
                  >
                    <FaRegCircleUser style={{marginTop:'-5px'}}/>
                    {this.props.i18n.t("my_profile")}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/learning"
                    style={{ fontWeight: "bold" }}
                  >
                    <RiHomeLine style={{marginTop:'-5px'}} />
                    {this.props.i18n.t('plans')}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/users" style={{ fontWeight: "bold" }}>
                  <PiCirclesFourLight style={{marginTop:'-5px'}} />

                    {this.props.i18n.t('encounters')}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                
                <MDBNavbarItem>
                  <MDBNavbarLink
                    href="/chatroom"
                    style={{ fontWeight: "bold" }}
                  >
                  <PiChatTeardropTextLight style={{marginTop:'-5px'}}/>
                    {this.props.i18n.t("chatroom")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("home_page_short")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink href="/logout" style={{ fontWeight: "bold" }}>
                    {this.props.i18n.t("sign_out")}
                  </MDBNavbarLink>
                </MDBNavbarItem>

                <div className={"mt-3"}>
                  <MDBIcon
                    flag="uk"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("en")}
                  />
                  <MDBIcon
                    flag="ir"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => changeLangBTN("fa")}
                  />
                </div>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
          {/*Profile image*/}
          <MDBRow className="mb-5">
            <MDBRow className="text-center">
              <div
                className="text-center mt-4"
                style={{ position: "relative", display: "inline-block", paddingRight:"0" }}
              >
                <GoArrowLeft
                  cursor={"pointer"}
                  onClick={(v) => this.goBack()}
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "40px",
                    fontSize: "30px",
                    color: "black",
                  }}
                />
                <img
                  height={180}
                  src={this.state.profile.picture}
                  className="shadow-5-strong"
                  style={{ borderRadius: "10%" }}
                  alt="Profile"
                />
                <label
                  htmlFor="inputField"
                  className="btn m-2"
                  style={{
                    background: "#C4C4C4",
                    color: "#fff",
                    position: "absolute",
                    bottom: "-20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <FaEdit /> {this.props.i18n.t("choose_avatar")}
                </label>
                <input
                  className="btn"
                  id="inputField"
                  style={{ display: "none" }}
                  type="file"
                  onChange={this.handleFileChange}
                />
              </div>
            </MDBRow>
          </MDBRow>

          {/* about me section */}
          <MDBRow className={"bg-white p-0 m-0"}>
            <div style={{ background: "#fff" }}>
              <label style={{ fontWeight: "bold" }}>
                {this.props.i18n.t("about_me")}
              </label>
              <textarea
                rows={8}
                name="about_me"
                className="form-control text-left ltr"
                onChange={(v) => this.about_me_Value(v)}
              >
                {this.state.profile.about_me}
              </textarea>
            </div>

            <div style={{ background: "#fff" }}>
              <label
                className={"mt-4"}
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >
                {this.props.i18n.t("My_info")}
              </label>
            </div>
          </MDBRow>
          <MDBRow className={"bg-white p-0 m-0"}>
            <MDBTable borderless className={"bg-white p-0 m-0"}>
              <MDBTableBody>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.i18n.t("username")}
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={this.state.profile.username}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("grade")}
                    </label>
                    <Select
                      options={this.degree_lst}
                      defaultValue={
                        this.degree_lst_en[this.state.profile.degree]
                      }
                      onChange={(value) => this.degreeValue(value)}
                      placeholder={this.props.i18n.t("grade")}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("major")}
                    </label>
                    <input
                      type="text"
                      name="major"
                      className="form-control"
                      onChange={(v) => this.majorValue(v)}
                      value={this.state.profile.major}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Occupation")}
                    </label>
                    <input
                      type="text"
                      name="Occupation"
                      className="form-control"
                      onChange={(v) => this.occupationInputValue(v)}
                      value={this.state.profile.occupation}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("City")}
                    </label>
                    <input
                      type="text"
                      name="weight"
                      className="form-control"
                      onChange={(v) => this.cityInputValue(v)}
                      value={this.state.profile.city_of_live}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Country")}
                    </label>
                    <input
                      type="text"
                      name="country"
                      className="form-control"
                      onChange={(v) => this.countryInputValue(v)}
                      value={this.state.profile.country_of_live}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Marital_Status")}
                    </label>
                    <Select
                      options={this.marital_status_lst}
                      defaultValue={
                        this.marital_status_lst[
                        this.state.profile.martial_status - 1
                        ]
                      }
                      onChange={(value) => this.marital_statusValue(value)}
                      placeholder={this.props.i18n.t("Marital_Status")}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Number_of_Children")}
                    </label>
                    <input
                      type="number"
                      name="weight"
                      className="form-control"
                      style={{ height: "38px" }}
                      onChange={(value) => this.number_of_childrenValue(value)}
                      value={this.state.profile.number_of_children}
                    />
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("language")}
                    </label>
                    <Select
                      components={{ Option: CheckboxOption }}
                      isMulti
                      options={this.languages_lst_en}
                      defaultValue={this.state.profile.languages}
                      onChange={value => this.languageValue(value)}
                      placeholder={this.props.i18n.t("language")}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("Height")}
                    </label>
                    <input
                      type="number"
                      name="height"
                      className="form-control"
                      onChange={(v) => this.heightInputValue(v)}
                      value={this.state.profile.height}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto"}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {this.props.i18n.t("weight")}
                    </label>
                    <input
                      type="number"
                      name="weight"
                      className="form-control"
                      onChange={(v) => this.weightInputValue(v)}
                      value={this.state.profile.weight}
                    />
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>

            {/* privacy section */}

            <div>
              <label
                className={"m-1 mt-5"}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.i18n.t("privacy_txt")}
              </label>
            </div>
            <MDBRow
              className={"mb-5"}
              style={{
                display: "grid",
                gridTemplateColumns: "10fr 2fr",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <label
                  htmlFor="show_verified"
                  style={{
                    marginLeft: "8px",
                    fontSize: "12px",
                    marginRight: "10px",
                  }}
                >
                  {this.props.i18n.t("privacy_check")}
                </label>
              </div>

              <div>
                <label
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "45px",
                    height: "23px",
                    marginRight: "40px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="show_verified"
                    defaultChecked={this.state.profile.show_verified}
                    onChange={(e) => {
                      console.log(e.target.checked); // Debugging log
                      this.state.profile.show_verified = e.target.checked; // Direct state change
                    }}
                    style={{
                      opacity: 0,
                      width: "0",
                      height: "0",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "#ccc",
                      transition: "0.4s",
                      borderRadius: "34px",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        content: '""',
                        height: "20px", // Ball height
                        width: "20px", // Ball width
                        borderRadius: "50%",
                        border: "1px solid black",
                        left: "2px", // Adjust to center the ball properly
                        bottom: "2px", // Adjust the vertical position of the ball
                        backgroundColor: "white",
                        transition: "0.4s",
                      }}
                    ></span>
                  </span>
                </label>

                <style>
                  {`
        input:checked + span {
          background-color: rgb(121, 214, 124)!important;
        }

        input:checked + span span {
          transform: translateX(21px); 
        }
      `}
                </style>
              </div>
            </MDBRow>


            <MDBRow className={"mb-5"}>

              <MDBCol
                className={
                  "text-center align-items-end justify-content-end d-flex"
                }
              >
                <button
                  className="btn"
                  style={{
                    background: "#FFF",
                    color: "#355070",
                    marginTop: "20px",
                    width: "120px",
                    border: "0.5px solid black",
                  }}
                  onClick={(v) => this.goBack()}
                >
                  <label>{this.props.i18n.t("cancel_btn_report")}</label>
                </button>
              </MDBCol>
              <MDBCol
                className={
                  "text-center align-items-end justify-content-start d-flex"
                }
              >
                <tr>
                  <td
                    style={{ whiteSpace: "nowrap", width: "1px" }}
                    className={"w-auto  align-bottom"}
                  >
                    <button
                      className="btn btn-success"
                      onClick={(v) => this.updateProfileSubmit(v)}
                      style={{
                        background: "#355070",
                        color: "#fff",
                        width: "185px",
                        marginTop: "20px",
                      }}
                    >
                      <label>
                        {this.props.i18n.t("save_btn")}
                      </label>
                    </button>
                  </td>
                </tr>
              </MDBCol>

            </MDBRow>
          </MDBRow>
        </body>
      );
    }
  }

  desktopView() {
    if (this.props.i18n.language === "fa") {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-right bg-body"
            dir="rtl"
            style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
          >
            <div className={"container-fluid"}>
              <RightSideNavbar
                profile={this.state.profile}
                page={"my-profile"}
              />

              <IntroPage />
            </div>
          </body>
        );
      }
      return (
        <body
          className="text-right bg-body"
          dir="rtl"
          style={{ height: "100vh" }}
        >
          <div
            className={"container-fluid"}
            style={{ backgroundColor: "#F5F5F5", position: "absolute" }}
          >
            <RightSideNavbar profile={this.state.profile} page={"my-profile"} />

            <div
              style={{
                margin: "50px 300px 50px 60px",
                padding: "10px 10px",
                backgroundColor: "white",
                borderRadius: "50px",
                border: "0.5px solid black",
              }}
            >
              {/*Profile image*/}
              <MDBRow className="mb-3">
                <MDBRow className="text-center">
                  <div
                    className="text-center mt-4"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <GoArrowRight
                      cursor={"pointer"}
                      onClick={(v) => this.goBack()}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "40px",
                        fontSize: "60px",
                        color: "black",
                      }}
                    />
                    <img
                      height={280}
                      src={this.state.profile.picture}
                      className="shadow-5-strong"
                      style={{ borderRadius: "10%" }}
                      alt="Profile"
                    />
                    <label
                      htmlFor="inputField"
                      className="btn m-2"
                      style={{
                        background: "#C4C4C4",
                        color: "#fff",
                        position: "absolute",
                        bottom: "-20px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <FaEdit /> {this.props.i18n.t("choose_avatar")}
                    </label>
                    <input
                      className="btn"
                      id="inputField"
                      style={{ display: "none" }}
                      type="file"
                      onChange={this.handleFileChange}
                    />
                  </div>
                </MDBRow>
              </MDBRow>
              <div className={""} style={{ margin: "100px 100px" }}>
                {/* about me section */}
                <MDBRow>
                  <MDBCol>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.i18n.t("about_me")}
                    </label>
                    <textarea
                      rows={6}
                      name="about_me"
                      className="form-control text-left ltr"
                      onChange={(v) => this.about_me_Value(v)}
                    >
                      {this.state.profile.about_me}
                    </textarea>
                  </MDBCol>
                </MDBRow>

                {/* Personal Info section */}
                <MDBRow className={"p-0 m-0 text-right"}>
                  <MDBCol>
                    <div>
                      <label
                        className={"m-1 mt-5"}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {this.props.i18n.t("My_info")}
                      </label>
                    </div>

                    <MDBRow className={"mt-4"}>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("username")}
                        </label>
                        <input
                          // disabled={true}
                          type="text"
                          name="username"
                          className="form-control"
                          value={this.state.profile.username}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("grade")}
                        </label>
                        <Select
                          options={this.degree_lst}
                          defaultValue={
                            this.degree_lst_en[this.state.profile.degree]
                          }
                          onChange={(value) => this.degreeValue(value)}
                          placeholder={this.props.i18n.t("grade")}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("major")}
                        </label>
                        <input
                          type="text"
                          name="major"
                          className="form-control"
                          onChange={(v) => this.majorValue(v)}
                          Value={this.state.profile.major}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Occupation")}
                        </label>
                        <input
                          type="text"
                          name="Occupation"
                          className="form-control"
                          onChange={(v) => this.occupationInputValue(v)}
                          value={this.state.profile.occupation}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className={"mt-3"}>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("City")}
                        </label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          onChange={(v) => this.cityInputValue(v)}
                          value={this.state.profile.city_of_live}
                        />
                      </MDBCol>

                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Country")}
                        </label>
                        <input
                          type="text"
                          name="country"
                          className="form-control"
                          onChange={(v) => this.countryInputValue(v)}
                          value={this.state.profile.country_of_live}
                        />
                      </MDBCol>

                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Marital_Status")}
                        </label>
                        <Select
                          options={this.marital_status_lst}
                          defaultValue={
                            this.marital_status_lst[
                            this.state.profile.martial_status - 1
                            ]
                          }
                          onChange={(value) => this.marital_statusValue(value)}
                          placeholder={this.props.i18n.t("Marital_Status")}
                        />
                      </MDBCol>

                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Number_of_Children")}
                        </label>
                        <input
                          type="number"
                          name="weight"
                          className="form-control"
                          style={{ height: "38px" }}
                          onChange={(value) =>
                            this.number_of_childrenValue(value)
                          }
                          placeholder={this.state.profile.number_of_children}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className={"mt-3"}>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("language")}
                        </label>
                        <Select
                          components={{ Option: CheckboxOption }}
                          isMulti
                          options={this.languages_lst_fa}
                          defaultValue={this.state.profile.languages}
                          onChange={value => this.languageValue(value)}
                          placeholder={this.props.i18n.t("language")}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("weight")}
                        </label>
                        <input
                          type="number"
                          name="weight"
                          className="form-control"
                          onChange={(v) => this.weightInputValue(v)}
                          value={this.state.profile.weight}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Height")}
                        </label>
                        <input
                          type="number"
                          name="Height"
                          className="form-control"
                          onChange={(v) => this.heightInputValue(v)}
                          value={this.state.profile.height}
                        />
                      </MDBCol>
                      <MDBCol></MDBCol>
                    </MDBRow>
                    <div>
                      <label
                        className={"m-1 mt-5"}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {this.props.i18n.t("privacy_txt")}
                      </label>
                    </div>
                    <MDBRow
                      className={"mb-5"}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ display: "inline-block" }}>
                        <label
                          htmlFor="show_verified"
                          style={{
                            marginLeft: "8px",
                            fontSize: "12px",
                            marginRight: "10px",
                          }}
                        >
                          {this.props.i18n.t("privacy_check")}
                        </label>

                        <label
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "45px",
                            height: "23px",
                            marginRight: "40px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="show_verified"
                            checked={this.state.profile.show_verified}
                            onChange={(e) => this.showVerifiedValue(e)}
                            style={{
                              opacity: 0,
                              width: "0",
                              height: "0",
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              backgroundColor: "#ccc",
                              transition: "0.4s",
                              borderRadius: "34px",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                border: "1px solid black",
                                left: "2px",
                                bottom: "2px",
                                backgroundColor: "white",
                                transition: "0.4s",
                              }}
                            ></span>
                          </span>
                        </label>

                        <style>
                          {`
        input:checked + span {
          background-color: rgb(121, 214, 124)!important;
        }

        input:checked + span span {
          transform: translateX(21px); 
        }
      `}
                        </style>
                      </div>
                    </MDBRow>

                    <MDBRow className={"mb-5"}>
                      <MDBCol
                        className={
                          "text-center align-items-end justify-content-center d-flex"
                        }
                      >
                        <button
                          className="btn"
                          style={{
                            background: "#355070",
                            color: "#fff",
                            width: "185px",
                            marginTop: "20px",
                          }}
                          onClick={(v) => this.updateProfileSubmit(v)}
                        >
                          <label>{this.props.i18n.t("save_btn_profile")}</label>
                        </button>
                      </MDBCol>
                      <MDBCol
                        className={
                          "text-center align-items-end justify-content-center d-flex"
                        }
                      >
                        <button
                          className="btn"
                          style={{
                            background: "#FFF",
                            color: "#355070",
                            marginTop: "20px",
                            width: "185px",
                            border: "0.5px solid black",
                          }}
                          onClick={(v) => this.goBack()}
                        >
                          <label>
                            {this.props.i18n.t("cancel_btn_report")}
                          </label>
                        </button>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </body>
      );
    } else {
      if (this.state.isIntro == null || this.state.isIntro == false) {
        return (
          <body
            className="text-left bg-body ltr"
            style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
          >
            <div className={"container-fluid"}>
              <RightSideNavbar
                profile={this.state.profile}
                page={"my-profile"}
              />
              <div
                style={{
                  marginLeft: "250px",
                  padding: "0px 10px",
                  backgroundColor: "#355070",
                }}
              >
                <IntroPage />
              </div>
            </div>
          </body>
        );
      }

      return (
        <body
          className="text-left bg-body ltr"
          style={{ height: "100vh", backgroundColor: "#F5F5F5" }}
        >
          <div
            className={"container-fluid"}
            style={{ backgroundColor: "#F5F5F5", position: "absolute" }}
          >
            <RightSideNavbar profile={this.state.profile} page={"my-profile"} />

            <div
              style={{
                margin: "50px 60px 50px 300px",
                padding: "10px 10px",
                backgroundColor: "white",
                borderRadius: "50px",
                border: "0.5px solid black",
              }}
            >
              {/*Profile image*/}
              {/* <MDBCol size={"4"}> */}
              <MDBRow className="mb-3">
                <MDBRow className="text-center">
                  <div
                    className="text-center mt-4"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <GoArrowLeft
                      cursor={"pointer"}
                      onClick={this.goBack}
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "40px",
                        fontSize: "60px",
                        color: "black",
                      }}
                    />
                    <img
                      height={280}
                      src={this.state.profile.picture}
                      className="shadow-5-strong"
                      style={{ borderRadius: "10%" }}
                      alt="Profile"
                    />
                    <label
                      htmlFor="inputField"
                      className="btn m-2"
                      style={{
                        background: "#C4C4C4",
                        color: "#fff",
                        position: "absolute",
                        bottom: "-20px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <FaEdit /> {this.props.i18n.t("choose_avatar")}
                    </label>
                    <input
                      className="btn"
                      id="inputField"
                      style={{ display: "none" }}
                      type="file"
                      onChange={this.handleFileChange}
                    />
                  </div>
                </MDBRow>
              </MDBRow>

              <div className={""} style={{ margin: "100px 100px" }}>
                {/* about me section */}
                <MDBRow>
                  <MDBCol>
                    <label style={{ fontWeight: "bold" }}>
                      {this.props.i18n.t("about_me")}
                    </label>
                    <textarea
                      rows={6}
                      name="about_me"
                      className="form-control text-left ltr"
                      onChange={(v) => this.about_me_Value(v)}
                    >
                      {this.state.profile.about_me}
                    </textarea>
                  </MDBCol>
                </MDBRow>

                {/* Personal Info section */}
                <MDBRow className={"p-0 m-0 text-left"}>
                  <MDBCol>
                    <div>
                      <label
                        className={"m-1 mt-5"}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {this.props.i18n.t("My_info")}
                      </label>
                    </div>

                    <MDBRow className={"mt-4"}>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("username")}
                        </label>
                        <input
                          // disabled={true}
                          type="text"
                          name="username"
                          className="form-control"
                          value={this.state.profile.username}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("grade")}
                        </label>
                        <Select
                          options={this.degree_lst_en}
                          defaultValue={
                            this.degree_lst_en[this.state.profile.degree]
                          }
                          onChange={(value) => this.degreeValue(value)}
                          placeholder={this.props.i18n.t("grade")}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("major")}
                        </label>
                        <input
                          type="text"
                          name="major"
                          className="form-control"
                          onChange={(v) => this.majorValue(v)}
                          Value={this.state.profile.major}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Occupation")}
                        </label>
                        <input
                          type="text"
                          name="Occupation"
                          className="form-control"
                          onChange={(v) => this.occupationInputValue(v)}
                          value={this.state.profile.occupation}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className={"mt-3"}>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("City")}
                        </label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          onChange={(v) => this.cityInputValue(v)}
                          value={this.state.profile.city_of_live}
                        />
                      </MDBCol>

                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Country")}
                        </label>
                        <input
                          type="text"
                          name="country"
                          className="form-control"
                          onChange={(v) => this.countryInputValue(v)}
                          value={this.state.profile.country_of_live}
                        />
                      </MDBCol>

                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Marital_Status")}
                        </label>
                        <Select
                          options={this.marital_status_lst_en}
                          defaultValue={
                            this.marital_status_lst_en[
                            this.state.profile.martial_status - 1
                            ]
                          }
                          onChange={(value) => this.marital_statusValue(value)}
                          placeholder={this.props.i18n.t("Marital_Status")}
                        />
                      </MDBCol>

                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Number_of_Children")}
                        </label>
                        <input
                          type="number"
                          name="weight"
                          className="form-control"
                          style={{ height: "38px" }}
                          onChange={(value) =>
                            this.number_of_childrenValue(value)
                          }
                          placeholder={this.state.profile.number_of_children}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className={"mt-3"}>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("language")}
                        </label>
                        <Select
                          components={{ Option: CheckboxOption }}
                          isMulti
                          options={this.languages_lst_en}
                          defaultValue={this.state.profile.languages}
                          onChange={value => this.languageValue(value)}
                          placeholder={this.props.i18n.t("language")}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("weight")}
                        </label>
                        <input
                          type="number"
                          name="weight"
                          className="form-control"
                          onChange={(v) => this.weightInputValue(v)}
                          value={this.state.profile.weight}
                        />
                      </MDBCol>
                      <MDBCol>
                        <label
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {this.props.i18n.t("Height")}
                        </label>
                        <input
                          type="number"
                          name="height"
                          className="form-control"
                          onChange={(v) => this.heightInputValue(v)}
                          value={this.state.profile.height}
                        />
                      </MDBCol>
                      <MDBCol></MDBCol>
                    </MDBRow>
                    <div>
                      <label
                        className={"m-1 mt-5"}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {this.props.i18n.t("privacy_txt")}
                      </label>
                    </div>
                    <MDBRow
                      className={"mb-5"}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ display: "inline-block" }}>
                        <label
                          htmlFor="show_verified"
                          style={{
                            marginLeft: "8px",
                            fontSize: "12px",
                            marginRight: "10px",
                          }}
                        >
                          {this.props.i18n.t("privacy_check")}
                        </label>

                        <label
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "45px",
                            height: "23px",
                            marginLeft: "40px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="show_verified"
                            checked={this.state.profile.show_verified}
                            onChange={(e) => this.showVerifiedValue(e)}
                            style={{
                              opacity: 0,
                              width: "0",
                              height: "0",
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              backgroundColor: "#ccc",
                              transition: "0.4s",
                              borderRadius: "34px",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                border: "1px solid black",
                                left: "2px",
                                bottom: "2px",
                                backgroundColor: "white",
                                transition: "0.4s",
                              }}
                            ></span>
                          </span>
                        </label>

                        <style>
                          {`
        input:checked + span {
          background-color: rgb(121, 214, 124)!important;
        }

        input:checked + span span {
          transform: translateX(21px); 
        }
      `}
                        </style>
                      </div>
                    </MDBRow>

                    <MDBRow className={"mb-5"}>
                      <MDBCol
                        className={
                          "text-center align-items-end justify-content-end d-flex"
                        }
                      >
                        <button
                          className="btn"
                          style={{
                            background: "#FFF",
                            color: "#355070",
                            marginTop: "20px",
                            width: "185px",
                            border: "0.5px solid black",
                          }}
                          onClick={(v) => this.goBack()}
                        >
                          <label>
                            {this.props.i18n.t("cancel_btn_report")}
                          </label>
                        </button>
                      </MDBCol>

                      <MDBCol
                        className={
                          "text-center align-items-end justify-content-center d-flex"
                        }
                      >
                        <button
                          className="btn"
                          style={{
                            background: "#355070",
                            color: "#fff",
                            width: "185px",
                            marginTop: "20px",
                          }}
                          onClick={(v) => this.updateProfileSubmit(v)}
                        >
                          <label>{this.props.i18n.t("save_btn_profile")}</label>
                        </button>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </div>
        </body>
      );
    }
  }

  render() {
    if (localStorage.getItem("token") === null) {
      return (
        <div style={{ fontWeight: "bold", fontSize: "25px", direction: "ltr" }}>
          Forbidden!
        </div>
      );
    }

    if (this.state.profile !== null) {
      return (
        <div>
          <ToastContainer />
          <BrowserView>{this.desktopView()}</BrowserView>
          <MobileView>{this.mobileView()}</MobileView>
        </div>
      );
    } else {
      return <di></di>;
    }
  }

  chatBTN(st) {
    window.location.href = "/chatroom";
  }
  goBack() {
    window.history.back();
  }
  heightInputValue(value) {
    this.setState({
      profile: {
        ...this.state.profile,
        height: parseInt(value.target.value),
      },
    });
    // this.state.profile.height = parseInt(value.target.value);
  }

  show_only_widowedValue(value) {
    console.log(value.target.checked);
    this.state.profile.is_happy_to_match_with_widow =
      !this.state.profile.is_happy_to_match_with_widow;
  }

  show_only_singlesValue(value) {
    console.log(value.target.checked);
    this.state.profile.is_happy_to_match_with_single =
      !this.state.profile.is_happy_to_match_with_single;
  }

  show_only_divorcedValue(value) {
    this.state.profile.is_happy_to_match_with_divorced =
      !this.state.profile.is_happy_to_match_with_divorced;
    console.log(this.state.profile.is_happy_to_match_with_divorced);
  }

  weightInputValue(value) {
    console.log(value.target.value);
    this.setState({
      profile: {
        ...this.state.profile,
        weight: parseInt(value.target.value),
      },
    });
    // this.state.profile.weight = parseInt(value.target.value);
  }

  cityInputValue(value) {
    this.setState({
      profile: {
        ...this.state.profile,
        city_of_live: value.target.value,
      },
    });
  }

  countryInputValue(value) {
    this.setState({
      profile: {
        ...this.state.profile,
        country_of_live: value.target.value,
      },
    });
  }

  showVerifiedValue(event) {
    const isChecked = event.target.checked;
  
    // Update the state using setState
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        show_verified: isChecked,
      },
    }));
  
  }

  
  occupationInputValue(value) {
    this.state.profile.occupation = value.target.value;

  }

  marital_statusValue(value) {
    this.state.profile.martial_status = parseInt(value["value"]);
  }

  languageValue(value) {
    if (value !== undefined && value.length > 0) {
      this.state.profile.languages = value;
    }
  }

  number_of_childrenValue(value) {
    this.state.profile.number_of_children = parseInt(value.target.value);
  }

  min_ageValue(value) {
    this.state.profile.min_age_for_match = parseInt(value.target.value);
  }

  max_ageValue(value) {
    this.state.profile.max_age_for_match = parseInt(value.target.value);
  }

  min_heightValue(value) {
    this.state.profile.min_height_for_match = parseInt(value.target.value);
  }

  max_heightValue(value) {
    this.state.profile.max_height_for_match = parseInt(value.target.value);
  }

  min_weightValue(value) {
    this.state.profile.min_weight_for_match = parseInt(value.target.value);
  }

  max_weightValue(value) {
    this.state.profile.max_weight_for_match = parseInt(value.target.value);
  }

  min_educationValue(value) {
    this.state.profile.min_education_for_match = value["value"];
  }

  max_educationValue(value) {
    this.state.profile.max_education_for_match = value["value"];
  }

  filterValue(value) {
    if (value !== undefined && value.length > 0) {
      this.state.profile.filters = value;
    }
  }

  majorValue(value) {
    this.state.profile.major = value.target.value;
  }

  about_me_Value(value) {
    if (this.isEnglish(value.target.value)) {
      this.state.profile.about_me = value.target.value;
    } else {
      toast.error(this.props.i18n.t("data_should_be_in_english"), {
        position: toast.POSITION.TOP_LEFT,
      });
      value.target.value = "";
    }
  }

  degreeValue(value) {
    this.state.profile.degree = value["value"];
  }

  updateProfileSubmit(st) {
    if (
      this.state.selectedFile !== null &&
      this.state.selectedFile !== undefined
    ) {
      this.handleUpload(st);
    }
  
    this.state.profile.filters = { filters: this.state.profile.filters };
    this.state.profile.languages = { languages: this.state.profile.languages };
  
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(this.state.profile),
    };
  
    if (
      this.state.selectedFile === null ||
      this.state.selectedFile === undefined
    ) {
      fetch("/api/v1/update-profile/", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 202) {
            this.setState(
              { profile: response.data },
              () => {
                window.location.href = "/my-profile"; // Redirect to the "Users" page
              }
            );
            toast.info(this.props.i18n.t("profile_update_toast"), {
              position: toast.POSITION.TOP_LEFT,
            });
          } else {
            toast.error(this.props.i18n.t("profile_update_error_toast"), {
              position: toast.POSITION.TOP_LEFT,
            });
          }
        })
        .catch((error) => {
          toast.error(this.props.i18n.t("profile_update_error_toast"), {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  }
  

  handleUpload(v) {
    if (
      this.state.selectedFile === null ||
      this.state.selectedFile === undefined
    ) {
      toast.info(this.props.i18n.t("profile_update_image_error_toast"), {
        position: toast.POSITION.TOP_LEFT,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("image", this.state.selectedFile);

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData,
    };

    fetch("/api/v1/upload-profile-image/", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 202) {
        this.setState({
          profile: data.data,
          selectedFile: null,
        }, () => {
          // Refresh the page after the state has been updated
          window.location.reload();
        });
      } else {
        toast.error(this.props.i18n.t("image_avatar_res_error"), {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    })
    .catch((error) => {
      toast.error(this.props.i18n.t("image_avatar_update_error_toast"), {
        position: toast.POSITION.TOP_LEFT,
      });
    });
  
  }

  birthdayValue(value) {
    this.state.profile.birthday = value.target.value;
  }

  handleFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
    const file = e.target.files[0];
    this.setState({ selectedFile: file }, () => {
      if (file) {
        this.handleUpload();
      }
    });
  };

  stringIsEnglish(str) {
    var ENGLISH = {};
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
      .split("")
      .forEach(function (ch) {
        ENGLISH[ch] = true;
      });
    var index;

    for (index = str.length - 1; index >= 0; --index) {
      if (!ENGLISH[str.substring(index, index + 1)]) {
        return false;
      }
    }
    return true;
  }

  isEnglish(str) {
    // var check = /^[a-zA-Z_0-9\u00C0-\u017F][a-zA-Z_0-9\u00C0-\u017F\s]*$/g.test(str);
    // var check = /^[a-zA-Z0-9\s,.\/]+$/g.test(str);
    // return check;
    return true;
  }
}

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

export default connect()(withParams(withTranslation()(EditProfile)));
