import {Component} from "react";
import {MDBCol, MDBRow} from "mdbreact";


class MobileChatRoom extends Component{
    constructor(props){
        super(props);


    }

    render(){

        if(this.props.is_receiver) {
            if (this.props.is_last){

                return (
                    <div className={'text-right p-1 mb-4'} >
                        <MDBRow style={{marginBottom: '30px'}}>
                            <MDBCol className={'text-right'} size={2}>

                                <img src={this.props.image} className={"img-fluid rounded-circle rounded-circle-custom"}
                                     style={{width: '40px', height: '40px'}}/>
                            </MDBCol>
                            <MDBCol className={'text-right'} size={10}>
                                <p className={"text-center"} style={{ background: '#8ED1DA',
                                    borderRadius: '24px 0px 24px 24px',
                                    padding: '10px', color: 'white', direction: 'ltr', fontFamily: 'IRANSansX',
                                    maxWidth: '100%', /* Prevents overflow */
                                    wordWrap: 'break-word'}}>{this.props.msg}</p>
                            </MDBCol>

                        </MDBRow>
                    </div>
                )
            }
            return (
                <div className={'text-right p-1 mt-3'}>
                    <MDBRow>
                        <MDBCol className={'text-right'} size={2}>

                            <img src={this.props.image} className={"img-fluid rounded-circle rounded-circle-custom"}
                                 style={{width: '40px', height: '40px'}}/>
                        </MDBCol>
                        <MDBCol className={'text-right'} size={10}>
                            <p className={"text-center"} style={{ background: '#8ED1DA',
                                borderRadius: '24px 0px 24px 24px',
                                padding: '10px', color: 'white', direction: 'ltr', fontFamily: 'IRANSansX',
                                maxWidth: '100%', /* Prevents overflow */
                                wordWrap: 'break-word'}}>{this.props.msg}</p>
                        </MDBCol>

                    </MDBRow>
                </div>
            )
        }else {

            if (this.props.is_last){
                return (
                    <div className={'text-left p-1 mb-4'} >
                        <MDBRow style={{marginBottom: '30px'}}>
                            <MDBCol className={'text-left'} size={10}>
                                <p className={"text-center"} style={{ background: '#007D8E',
                                    borderRadius: '24px 24px 24px 0px', padding: '10px',
                                    fontFamily: 'IRANSansX',
                                    direction: 'ltr',
                                    color: 'white',
                                    maxWidth: '100%', /* Prevents overflow */
                                    wordWrap: 'break-word'}}>{this.props.msg}</p>
                            </MDBCol>
                            <MDBCol className={'text-left'} size={2}>
                                <img src={this.props.image} className={"img-fluid rounded-circle rounded-circle-custom"}
                                     style={{width: '40px', height: '40px'}}/>
                            </MDBCol>
                        </MDBRow>
                    </div>
                )
            }

            return (
                <div className={'text-left p-1'} >
                    <MDBRow>
                        <MDBCol className={'text-left'} size={10}>
                            <p className={"text-center"} style={{ background: '#007D8E',
                                borderRadius: '24px 24px 24px 0px', padding: '10px',
                                fontFamily: 'IRANSansX',
                                direction: 'ltr',
                                color: 'white',
                                maxWidth: '100%', /* Prevents overflow */
                                wordWrap: 'break-word'}}>{this.props.msg}</p>
                        </MDBCol>
                        <MDBCol className={'text-left'} size={2}>
                            <img src={this.props.image} className={"img-fluid rounded-circle rounded-circle-custom"}
                                 style={{width: '40px', height: '40px'}}/>
                        </MDBCol>


                    </MDBRow>
                </div>
            )
        }
    }
}

export default MobileChatRoom;